@value clrText, clrWhite, clrBorderLight, clrBorder, clrError, clrSuccess, clrLightGray, clrGray from '../../vars.css';

.inputRoot {
    clear: both;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
}

.innerFieldRoot {
    margin-top: -15px;
}

.paddingnone {
    padding: 0;
}

.paddingsmall {
    padding-bottom: 10px;
}


.inputRootFullLabel {
    flex-direction: column;
}

.inputRootFullLabel .inputWrapper {
    width: auto;
}

.inputRootHalfLabel .inputWrapper {
    width: 50%;
}

.label {
    min-width: 160px;
    line-height: 1.43rem;
    padding: 9px 0 0 0;
    font-weight: normal;
    margin-bottom: 5px;
    display: inline-block;
}

.labelIcon {
    margin-right: 0.5rem;
}

.labelWidthfull {
    width: auto;
    flex: 1 1 auto;
    margin-bottom: 10px;
}

.labelWidthhalf {
    width: 50%;
    flex: 1 1 auto;
    margin-bottom: 10px;
    position: relative;
}

.fieldValue {
    position: absolute;
    top: 9px;
    left: 175px;
    font-weight: bold;
}

.inputWrapper {
    position: relative;
    flex: 1 1 auto;
    padding-right: 20px;
    margin-top: 0;
    width: 100%;
}

.inputWrapper.noValidate {
    padding-right: 0;
}

.prefix,
.input {
    font: 1rem/1.5 Arial, 'Helvetica Neue', Helvetica, sans-serif;
    line-height: 1.57rem;
    min-height: 2.14rem;
    padding: 7px 8px;
    border: 1px solid clrBorderLight;
    color: clrText;
    background-color: clrWhite;
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    box-shadow: inset 0 1px 1px #e9e7e7;
    border-radius: 4px;
    background-clip: padding-box;
}

.input.dropdownList {
    height: 38px;
    padding: 0;
}

.input.dropdownList > :global(.rw-widget-picker) {
    padding: 0;
    margin: 0 0 1px 0;
    border-radius: 4px;
}

.input.dropdownList > :global(.rw-widget-picker) > :global(.rw-input) {
    background: transparent !important;
}

.input.dropdownList > :global(.rw-popup-container) {
    top: calc(100% + 1px);
}

.input.dropdownList :global(.rw-filter-input) :global(.rw-select) {
    vertical-align: top;
}

.input.dropdownList .loader {
    min-height: 50px;
}

.prefix {
    position: absolute;
    border: none;
    box-shadow: none;
    width: auto;
    background-color: transparent;
    line-height: 1.71rem;
    padding-left: 9px;
    padding-right: 4px;
}

.prefixWrapper {
    display: flex;
}

.prefixWrapper .prefix {
    border: 1px solid clrBorderLight;
    border-right: none;
    position: static;
    padding-right: 9px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.prefixWrapper .prefix + .input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input[readonly] {
    color: clrBorder;
}

select.input.inputEmpty,
.input::placeholder,
.input ::placeholder {
    color: #999;
}

select.input {
    height: 38px;
}

select.input option {
    color: clrText;
}

.input:focus,
.input:focus + .charCounter {
    border-color: #3875d7;
    outline: 0;
}

.inputDisabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.inputDisabled .inputDisabled {
    opacity: 1;
}

.inputInvalid, .inputInvalid :global(.ql-container .ql-editor) {
    border-color: #b94a48;
}

.error {
    position: relative;
    background-color: #d32f2f;
    color: #ffffff;
    font-size: 0.86rem;
    padding: 4px 10px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
}

.error:before {
    content: '';
    display: block;
    position: absolute;
    border: 7px outset transparent;
}

.errorBelow {
    display: inline-block;
    margin: 10px 0 0 0;
}

.errorBelow:before {
    left: 22px;
    top: -13px;
    border-bottom: 7px solid #d32f2f;
}

.validationIcon {
    position: absolute;
    right: 0;
    top: 13px;
    font-size: 0.86rem;
}

.validationIcon-asterisk {
    top: 11px;
    right: 2px;
    color: #999999;
    font-size: 1.43rem;
}

.validationIcon-cross {
    color: clrError;
}

.validationIcon-tick {
    color: clrSuccess;
}

.textarea {
    resize: none;
    display: block;
}

.limitedTextarea {
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
    box-sizing: border-box;
    height: 6em;
    border-radius: 4px 4px 0 0;
    background-clip: padding-box;
}

.limitedTextarea.singleLine {
    height: auto;
}

.limitedRichText :global(.ql-container .ql-editor) {
    border-radius: 4px 4px 0 0;
}

.charCounter {
    position: relative;
    float: right;
    text-align: right;
    background-color: #e6e6e6;
    color: #808080;
    margin: -1px 0 0;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}

.charCounter div {
    padding: 5px 10px 4px 0;
}

.inputInvalid + .charCounter {
    border-color: #b94a48;
}

.radioWrapper {
    display: flex;
}

.radioButton {
    display: inline-flex;
    align-items: center;
    padding-top: 5px;
    margin: 0 20px 5px 0;
}

.radioButton:last-child {
    margin-right: 0;
}

.radioButton > input {
    margin: 0 6px 0 0;
    position: relative;
    top: 1px;
}

.radioWrapper.verticalRadio {
    flex-direction: column;
}
.verticalRadio .radioButton {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
}

.verticalRadio .radioButton > input {
    margin-top: -2px;
}

.radioButton .radioButtonExtra {
    display: block;
    padding-top: 5px;
    width: 100%;
}

.optionDescription {
    display: block;
}

.optionDescriptionHr {
    margin: 10px 0 !important;
}

.buttonStyle {
    cursor: pointer;
    min-width: 79px;
    height: 50px;
    padding: 16px 5px 16px 0;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #f4f5f7;
    box-sizing: border-box;
    text-align: center;
    position: relative;
}

.buttonStyleActive {
    border: solid 1px #27a2c7;
    cursor: default;
}

.buttonStyleActive .triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 25px solid #27a2c7;
    border-left: 25px solid transparent;
}

.buttonStyleActive .iconTick {
    color: clrWhite;
    margin-left: 5px;
    vertical-align: text-top;
    position: absolute;
    top: -1px;
    right: -1px;
}

.buttonStyle input {
    appearance: none;
}

.checkboxArray {
    min-width: 100px;
    display: block;
    padding-bottom: 5px;
}

.checkboxArrayHorizontal .checkboxArray {
    display: inline-block;
    margin-right: 20px;
}

.fileUploader {
    position: relative;
    background-color: clrLightGray;
    border-radius: 8px;
    border: 2px dashed clrBorder;
    background-clip: initial;
    overflow: hidden;
    padding: 30px 2%;
    text-align: center;
}

.fileUploader input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    color: transparent;
    text-indent: -2em;
}

.fileUploader > p {
    margin-bottom: 1em;
    font-size: 1rem;
    color: clrGray;
}

.fileUploaderButton {
    margin: 10px 0 0;
}

.tipInfo {
    color: clrWhite;
    margin-left: 5px;
    vertical-align: text-top;
    line-height: 1;
}

.tipInfoIcon:before {
    color: clrWhite;
}

.multiSelectButtons {
    padding: 8px;
    border-bottom: 1px solid clrBorder;
}

.multiSelectButtons .multiSelectButton {
    margin-bottom: 0;
}

.checkboxWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.checkboxWrapper input {
    margin-left: 0;
}

.checkboxWrapper .label {
    padding: 0;
    margin: 0 0 0 5px;
}

.timePicker {
    display: flex;
}

.timePicker select {
    width: 90px;
    margin-left: 15px;
}

.timePicker select:first-child {
    margin-left: 0;
}

.colourPicker, .hexInput, .rgbInput, .rgbPart {
    display: inline-flex;
    align-items: center;
}

.colourPicker.vertical {
    flex-direction: column;
    align-items: flex-start;
}

.colourPicker.vertical .hexInput {
    margin-bottom: 10px;
}

.colourPickerWrapper {
    display: flex;
    align-items: stretch;
}

.colourPickerWrapper.withPreview {
    background: clrLightGray;
    padding: 15px 15px 15px 0;
}

.colourPickerWrapper.withPreview .colourPickerColour {
    margin: -15px 12px -15px 0;
}

.colourPickerColour {
    width: 20px;
    min-height: 65px;
    margin-right: 7px;
}

.rgbPart input {
    width: 43px;
}

.hexInput > span, .rgbInput > span, .rgbPart > span {
    margin-right: 3px;
}

.colourPicker:not(.vertical) .hexInput + .rgbInput {
    margin-left: 11px;
}

.rgbPart + .rgbPart {
    margin-left: 7px;
}

.colourSwitchWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 45px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.colourSwitchWrapper .colour {
    width: 45px;
    height: 45px;
    list-style: none;
    box-sizing: border-box;
    transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border: 1px solid rgba(255,255,255,0);
    cursor: pointer;
}

.colourSwitchWrapper .colour:hover {
    border-color: #323A49;
}

.colourSwitchWrapper .colour.active {
     border: 1px solid #323A49;
     box-shadow: inset 0 0 0 2px #fff;
}

.spaces {
    background: clrLightGray;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spaces .space {
    display: flex;
    align-items: center;
    margin-left: 11px;
}

.spaces .space:first-child {
    margin-left: 0;
}

.spaces .space > span {
    display: inline-block;
    background-color: clrWhite;
    border: 1px solid clrBorder;
    position: relative;
    padding: 0 4px;
    margin-right: 4px;
    user-select: none;
}
.spaces .space > span {
    margin-right: 8px;
}

.spaces .space > span:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    border: 1px solid clrBorder;
}

.spaces .spaceB > span:after,
.spaces .spaceT > span:after {
    top: -5px;
    left: -1px;
    right: -1px;
    width: auto;
}

.spaces .spaceB > span:after {
    top: auto;
    bottom: -5px;
}

.spaces .spaceL > span:after,
.spaces .spaceR > span:after {
    left: -5px;
    top: -1px;
    bottom: -1px;
    height: auto;
}

.spaces .spaceR > span:after {
    left: auto;
    right: -5px;
}

.spaces .paddingSpace.spaceT > span:after { top: -3px; }
.spaces .paddingSpace.spaceB > span:after { bottom: -3px; }
.spaces .paddingSpace.spaceL > span:after { left: -3px; }
.spaces .paddingSpace.spaceR > span:after { right: -3px; }

