@import "src/styles/config";

.text {
    margin-top: 10px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.salesPreview {
    img {
        padding: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background: $clrWhite;
        max-width: 100%;
        max-height: 200px;
    }

    p {
        margin: 15px 0;
    }
}

.empty {
    background: $clrWhite;
    padding: 20px;
    border: 1px solid $clrBorder;
}
