@import "src/styles/config";

.wrapper {
    display: inline-block;
    background: $clrBorder;
    border-radius: 11px;
    height: 22px;
    line-height: 22px;
    width: 50px;
    box-sizing: border-box;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    font-size: 1rem;

    &.active {
        padding-left: 6px;
        padding-right: 25px;
        color: $clrWhite;
    }
}

.circle {
    width: 18px;
    height: 18px;
    border-radius: 100px;
    box-shadow: 0 2px 3px 0 rgba(102, 102, 102, 0.3);
    background-color: $clrGray1;
    position: absolute;
    left: 3px;
    top: 2px;
    transition: left 0.3s;
}

.active .circle {
    left: 29px;
}

