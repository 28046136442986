@value clrWhite from '../../vars.css';
@value clrBorder: #d8d8d8;
@value clrActive: #d6ecf4;
@value clrPass: #f2f2f2;
@value clrNumberBg: #c1c4d2;
@value clrIcon: #666666;
@value clrIconActive: #ffffff;

.wrapper {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    margin: 25px 0;
}

.step {
    flex: 1 1 auto;
    line-height: 3.21rem;
    padding: 0 10px 0 20px;
    margin-right: 16px;
    border: 1px solid clrBorder;
    border-right: none;
    background-color: clrWhite;
    text-align: center;
    vertical-align: middle;
    position: relative;
    font-weight: bold;
    white-space: nowrap;
}

.step.stepNumbered {
    text-align: left;
}

.step.withTooltip {
    text-align: left;
    padding-left: 20px;
}

.arrowStart {
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 12px;
    overflow: hidden;
}

.arrowEnd {
    position: absolute;
    right: -12px;
    top: -1px;
    bottom: -1px;
    width: 12px;
    overflow: hidden;
}

.arrowEnd > span,
.arrowStart:before,
.arrowEnd:before,
.arrowStart:after,
.arrowEnd:after {
    content: '';
    position: absolute;
    top: -1px;
    width: 0;
    height: 0;
    border-top: 24px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 12px solid clrBorder;
}
.arrowStart:after,
.arrowEnd:after {
    border-left-color: clrWhite;
}
.arrowStart:before {
    left: 0;
}
.arrowStart:after {
    left: -1px;
}
.arrowEnd:before {
    right: 0;
}
.arrowEnd:after {
    right: 1px;
}

.step:last-child {
    border-right: 1px solid clrBorder;
    margin-right: 0;
}
.step:last-child .arrowEnd {
    display: none;
}

.step:first-child .arrowStart {
    display: none;
}

.step.active .arrowEnd:after {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
}
.step.active .arrowEnd:before {
    border-top-color: clrWhite !important;
    border-bottom-color: clrWhite !important;

    border-left-color: clrBorder !important;
}

.step.active .tipInfoIcon {
    color: clrIconActive;
}

.step.pass {
    background-color: clrPass;
}
.step.pass .arrowEnd:after {
    border-left-color: clrPass;
}

.number {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    margin-right: 13px;
    color: clrWhite;
    background: clrNumberBg;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
}

.tipInfo {
    color: clrBorder;
    margin-left: 5px;
    float: right;
}

.tipInfo > span {
    vertical-align: middle;
}

.tipInfoIcon {
    color: clrIcon;
}

.tipInfoIcon:before {
    position: absolute;
    top: 4px;
    line-height: 1.5em;
    left: 0;
    right: 0;
}

.stepWithDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-height: 80px;
    line-height: 1.5rem;
}

.stepDescription {
    font-size: 24px;
    margin-top: 10px;
}
