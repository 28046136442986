.wrapper {
    display: flex;
    align-items: flex-start;
}

.imgWrapper {
    margin-right: 40px;
    text-align: center;
    padding: 5px;
    border: 5px solid #f2f2f2;
    line-height: 1;
}

.img {
    margin: 0 auto;
    max-width: 450px;
    max-height: 646px;
    height: auto;
}

.assetDetails table {
    table-layout: fixed;
    margin-bottom: 20px;
    font-size: 0.93rem;
}

.assetDetails table td,
.assetDetails table th {
    padding: 0.5em 0;
    height: auto;
    text-align: left;
}

.assetDetails table th {
    padding-right: 1em;
}

.assetDetails p {
    margin: 0 0 10px 0;
}

.assetDetails b {
    font-weight: bold;
}

.assetDescription {
    margin-bottom: 2em;
}

.rightButton {
    float: right;
    margin-top: -15px;
    z-index: 2;
    position: relative;
}

.textarea label {
    margin-bottom: 30px;
    margin-top: -35px;
}

.textareaBig textarea {
    height: 1000px;
    color: #656565;
}
