.wrapper {
    padding: 15px 24px 15px;
    position: sticky;
    top: 0;
    z-index: 12;
    background-color: #f4f5f7;
    box-shadow: 0 0 10px rgba(235, 236, 240, 0.2);
    display: flex;
    align-items: center;
    min-height: 42px !important;
    flex: 0 0 42px !important;
}

.title {
    flex: 1 1 auto;
}

.actions {
    flex: 0 0 auto;
}

.campaign {
    font-size: 1rem;
    line-height: 1.43;
    color: #6b778c;
}

.template {
    display: block;
    font-size: 1.14rem;
    line-height: 1.25;
    font-weight: bold;
    color: #172b4d;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 2px;
}

.stats {
    margin: 0 5px 0 15px;
}
