.searchContainer {
  display: flex;

  label {
    min-width: fit-content;
    padding-right: 13px;
  }

  & > div {
    min-width: 314px;

    &:last-child {
      flex: 1;
    }
  }

  .searchField {
    min-width: 290px;
  }
}

.filtersWrapper {
  background: #f4f5f7;
  padding: 15px 0 15px 20px;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-weight: bold;
    min-width: unset;
    margin-right: 10px;
  }

  .listItem {
    margin-bottom: 10px;
  }
}

.filtersContainer {
  display: flex;
  align-items: center;

  h3 {
    flex: 1;
    margin: 0;
  }

  & > button {
    margin-left: 15px;
  }
}

.filtersControls {
  display: flex;
  align-items: center;

  & > div + div {
    margin-left: 15px;
  }
}

.filters {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

