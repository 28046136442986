$width: 970px;
$padding: 2*21px;
$space: 35px;

.wrapper {
    display: flex;
    flex-flow: row wrap;
}

.wrapper1 > div {
    margin-left: 0;
}

.wrapper2 > div {
    flex: 1 0 45%;
    max-width: calc(($width - $space)/2 - $padding);

    &:nth-of-type(2n + 1) {
        margin-left: 0;
    }
}

.wrapper3 > div {
    flex: 1 0 25%;
    max-width: calc(($width - 2*$space)/3 - $padding);

    &:nth-of-type(3n + 1) {
        margin-left: 0;
    }
}

.wrapper4 > div {
    flex: 1 0 15%;
    max-width: calc(($width - 3*$space)/4 - $padding);

    &:nth-of-type(4n + 1) {
        margin-left: 0;
    }
}

.search > div {
    padding-right: 0;
}
