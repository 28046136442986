.wrapper {
    border-top: 3px solid #ccc;
    overflow: hidden;
    margin-top: 40px;
    padding: 30px 0 15px;
    background: #f2f2f2;
}

.hidden {
    display: none;
}

.pullRight {
    float: right;
    text-align: right;
}

.h4 {
    margin: 0;
    font-size: 0.93rem;
    font-weight: bold;
    line-height: 2.23;
}

.list li {
    display: inline-block;
    font-size: 0.93rem;
    color: #808080;
    padding: 0 10px;
    border-right: 1px solid #808080;
    margin: 0 0 5px 0;
}

.list li:last-child {
    border-right: none;
    padding-right: 0;
}

.list a {
    color: #808080 !important;
    text-decoration: none;
}

.logo {
    background: transparent url(./footer-logo.png) no-repeat center left;
    margin-left: 3px;
    width: 148px;
    height: 24px;
    vertical-align: top;
    text-indent: -999999px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    bottom: 2px;
}
