.wrapper {
    width: 100%;
    box-sizing: border-box;
    max-height: 80vh;
    padding: 15px 0;
    background-clip: padding-box;
    outline: 0;
    overflow: hidden;
    overflow-y: auto;
    z-index: 2;
    font-size: 1rem;
}

.wrapper p {
    font-style: italic;
}
