.button {
    width: 1.929rem;
    height: 1.929rem;
    border: 1px solid #d8d8d8;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.button.disabled {
    opacity: 0.5;
}

.icon {
    font-size: 0.86rem;
    color: #6b778c;
}
