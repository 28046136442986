@value clrOrange from '../../../../vars.css';

@value clrBlockBg: #f2f2f2;

@value clrHr: #cccccc;

.arrow {
    margin-left: 9px;
}

.block {
    background-color: clrBlockBg;
    padding: 20px 20px 15px 20px;
    width: 250px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    font-size: 0.86rem;
    line-height: 1.5;
}

.block a {
    color: clrOrange;
}

.block h1 {
    margin: 0 0 5px 0;
    font-size: 1.29rem;
    line-height: 1.43;
    height: auto;
}

.block h4 {
    font-size: 0.86rem;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 0 5px 0;
}

.block p {
    font-size: 0.86rem;
    line-height: 1.5;
    margin: 0 0 5px 0;
}

.block hr {
    border-top: 2px dotted clrHr;
}

.block:before {
    content: '\e605';
    font-family: 'partnermarketing' !important;
    speak: none;
    font-style: normal !important;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 2.143rem;
    right: 0;
    position: absolute;
    top: -1px;
    color: clrBlockBg;
}

.menu {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 20px 0 0 0;
    left: auto;
    right: 0;
}

html body :global(#react-root) .wrapper :global(button.small).button {
    border: none;
    color: white !important;
    text-align: center;
    text-decoration: none;
    display: inline-flex !important;
    font-size: 25px !important;
    border-radius: 50% !important;
    cursor: pointer;
    width: 41px !important;
    height: 41px !important;
    font-family: inherit !important;
    justify-content: center;
    align-items: center;
    padding: 0;
}
