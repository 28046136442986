@import "src/styles/config";

.options {
    & > label {
        background: $clrBorderLight;
        border-radius: 4px;
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 15px;
        padding: 10px 10px 9px;

        input {
            margin: 0;
        }

        label {
            margin-bottom: 5px;
        }

        .label {
            flex: 1;
            margin-left: 5px;
        }

        .emailUrl {
            padding-left: 51px;
        }
    }
}
