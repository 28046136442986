@import "src/styles/config";

.wrapper {
    background: $clrGray1;
    padding: 12px 15px 17px;
    border-radius: 4px;
    margin: 10px 0;
}

.iconRow {
    display: flex;
    label, input {
        cursor: pointer;
    }
}

.icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    color: $clrWhite;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 2.143rem;
    font-size: 1.357rem;

    &.twitter {
        background: #000000;
    }

    &.linkedin {
        background: #007ab6;
    }

    &.facebook {
        background: #4866a8;
    }

    &.youtube {
        background: #ff3333;
    }

    &.googleplus {
        background: #c73d2d;
    }

    &.xing {
        background: #005a5f;
    }

    &.instagram {
        background: #517ea3;
        font-size: 1.143rem;
    }

    &.vimeo {
        background: #00aced;
    }

    &.pinterest {
        background: #c91f27;
        font-size: 1.643rem;
    }

    &.blog {
        background: #4f4f4f;
    }

    &.wechat {
        background: #51c332;
    }

    &.weibo {
        background: #e1162c;
    }

    &.youku {
        background: #00a8fe;
    }

    &.disabled {
        background: #b1b4b3;
    }
}

.iconOffset {
    & + & {
        margin-left: 7px;
    }
}
