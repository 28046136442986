.switcherDropDown {
    display: inline-block;
}

.switcherDropDown ul {
    min-width: calc(100% + 1px);
    right: 0;
    left: auto;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(204, 204, 204, 0.5);
    border: 1px solid #c1c4d2;
    box-sizing: border-box;
    margin-right: -1px;
    padding: 5px 0;
}

.switcherDropDown ul li {
    text-align: right;
    cursor: pointer;
}

.switcherDropDown ul > li > a {
    padding: 2px 10px 2px 10px;
}

.switcherDropDown ul > li > a::first-letter {
    text-transform: capitalize;
}

.switcherDropDown.empty ul {
    display: none;
}

.switcherDropDownArrow {
    margin-left: 8px;
}

.switcherDropDownButton {
    background: gray;
    margin: 0 10px 0 0;
}

.switcherDropDownButtonSpan {
    display: inline-block;
}

.switcherDropDownButtonSpan::first-letter {
    text-transform: capitalize;
}

.switcherDropDownIcon {
    margin-right: 8px;
}
