.wrapper {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: help;
}

.versionWrapper {
    display: inline-block;
    position: relative;
    cursor: help;

    & > span {
        position: absolute;
        top: 1px;
        right: -11px;
    }
}

.tip {
    max-width: none;
}
