.stack {
    position: relative;
    vertical-align: top;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.x05 {
    width: 1.2em;
    height: 1.2em;
}
.x05.outer {
    font-size: 1.2em;
}
.x05 .outer {
    font-size: 1.2em;
}
.x05 .inner {
    height: 1.8em;
    font-size: 0.65em;
    line-height: 1.8em;
}

.x1 {
    width: 1.5em;
    height: 1.5em;
}
.x1.outer {
    font-size: 1.5em;
}
.x1 .outer {
    font-size: 1.5em;
}
.x1 .inner {
    font-size: 0.75em;
}

.x2 {
    width: 2rem;
    height: 2rem;
}
.x2.outer {
    font-size: 2rem;
}
.x2 .outer {
    font-size: 2rem;
}
.x2 .inner {
    font-size: 1.2rem;
}

.x3 {
    width: 3em;
}
.x3 .outer {
    font-size: 3em;
}
.x3 .inner {
    font-size: 1.3em;
}

.x4 {
    width: 4rem;
    height: 4rem;
}
.x4 .outer {
    font-size: 4rem;
}
.x4 .inner {
    font-size: 1.6rem;
}

.x5 {
    width: 5em;
    height: 5em;
}
.x5.outer {
    font-size: 5em;
}
.x5 .outer {
    font-size: 5em;
}
.x5 .inner {
    font-size: 2.1em;
}

.outer,
.inner {
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
}

.inner {
    line-height: inherit;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.outer-bg-triangle + .inner {
    top: 2px;
}

.outer-bg-triangle.x4 + .inner {
    top: 6px;
}

