@value clrWhite, clrGray, clrBorderLight, clrBorder from '../../vars.css';
@value clrGreyDark: #666666;
@value clrBlack: #000000;
@value clrGrayDusty: #999999;

.applicationSwitcher {
    display: flex;
    height: 115px;
    margin-bottom: 25px;
}

.applicationSwitcher--no-icons {
    height: 3.929rem;
    margin-bottom: 40px;
}

.applicationSwitcherDetails {
    height: 75px;
}

.applicationTab {
    position: relative;
    flex-basis: 50%;
    flex-grow: 1;
    border-style: solid;
    border-color: clrBorderLight;
    border-width: 1px;
    cursor: pointer;
}

.applicationTabLast {
    border-width: 1px 1px 1px 0;
}

.applicationTabFirst {
    border-width: 1px 0 1px 1px;
}

.applicationTabActive {
    border-width: 1px;
}

.applicationTabActive .borderArrowWrapper {
    position: absolute;
    top: auto;
    bottom: -20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    height: 20px;
    background-color: clrWhite;
    overflow: hidden;
}

.applicationTabActive .borderArrowWrapper .borderArrow {
    height: 14px;
    width: 14px;
    border: 1px solid clrBorder;
    border-radius: 2%;
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 12px;
}

.applicationTab .appTitle {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: clrGreyDark;
}

.applicationTab .appTitleDetails {
    bottom: 10px;
}

.appIcon {
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background-color: clrBlack;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.appIcon.appSwitcherIconcampaignBuilder {
    background: clrBlack url('./thumb-envelope-bubble.png') no-repeat center center;
}

.appIcon.appSwitcherIconmarketingStore {
    background: clrBlack url('./hand-window.png') no-repeat center center;
}

.appIcon.appSwitcherIconassetLibrary {
    background: clrBlack url('./two-sheets.png') no-repeat center center;
}

.applicationTab .appIconDetails {
    top: 10px;
    width: 27px;
    height: 27px;
    background-color: clrGrayDusty;
    text-align: center;
}

.appIcon .itemsCount {
    color: clrWhite;
    font-weight: bold;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.appIcon .itemsCount.customIcon:before {
    line-height: 1.5rem;
}
