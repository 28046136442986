@import "src/styles/config";

.wrapper {
    background: $clrWhite;
    box-shadow: 0 2px 4px 0 #ebecf0;
    position: sticky;
    top: 0;
    z-index: 3;
    margin-bottom: 30px;
}

.filtersContainer {
    background-color: $clrGray1;
}

.filters {
    padding: 25px 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        display: flex;
        align-items: center;

        & > button {
            margin: 0 15px 0 0;
        }
    }

    .applied {
        margin: 0 15px;
    }

    h3 {
        margin: 0 !important;
    }
}

.filtersWrapper {
    padding: 25px 0;
    background-color: $clrGray1;
    border-top: 1px solid $clrBorder1;

    label {
        font-weight: bold;
    }

    &.mainFilters {
        border-top: none;
        padding-top: 15px;
        border-bottom: 1px solid $clrBorder1;
    }

    &.additionalFilters {
        border-top: none;
        padding-top: 0;
    }
}

.searchWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.resultsCount {
    margin-right: 20px;
    color: $clrDarkBlue;
    white-space: nowrap;
}

.searchContainer {
    padding: 25px 0;
    margin-right: -20px;
    display: flex;

    label {
        min-width: fit-content;
        padding-right: 13px;
    }

    & > div {
        min-width: 314px;

        &:last-child {
            flex: 1;
        }
    }
}

.searchField {
    min-width: 290px;
}

.emptySearch {
    padding-top: 0;
}

.filterIconShared {
    float: right;
    display: inline-block;
}
