@value clrBorder, clrWhite from '../../vars.css';

.root {
    background: clrWhite;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    float: left;
    min-width: calc(100% - 2px);
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 1rem;
    background-clip: padding-box;
}

.item {
    margin: 0 0 5px 0;
    text-align: left;
    cursor: pointer;
}

.item:last-child {
    margin-bottom: 0;
}

.item > a {
    transition: all 0.15s ease-in-out;
    text-decoration: none;
    font-weight: normal;
    color: #4d4d4d;
    display: block;
    padding: 3px 20px;
    clear: both !important;
    line-height: 1.428571429;
    white-space: nowrap;
}

.item > a:hover {
    background: #ededed;
}
