.quill .ql-toolbar.ql-snow {
    margin: -5px 0;
}

.ql-snow .ql-picker.ql-short-codes .ql-picker-label {
    line-height: 18px;
    display: block;
}

.quill .ql-toolbar.ql-snow .ql-formats {
    margin: 5px 5px 5px 0;
}

.ql-snow .ql-picker.ql-short-codes .ql-picker-item::before {
    content: attr(data-label);
}

.ql-snow .ql-picker.ql-short-codes .ql-picker-label::before {
    content: attr(data-label);
    padding-right: 20px;
    line-height: 1;
}

.ql-toolbar.ql-snow .ql-picker.ql-short-codes.ql-expanded .ql-picker-label {
    border-color: transparent;
}

.ql-snow .ql-picker.ql-short-codes .ql-picker-options .ql-picker-item:first-child {
    display: none;
}


.ql-snow .ql-picker.ql-short-codes {
    background-color: #f7f7f9;
    padding: 4px 0;
    color: #333;
    border: 1px solid #ccc;
    height: 30px;
    line-height: 1.571rem;
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    user-select: none;
    border-radius: 4px;
}

.quill .ql-editor {
    overflow-wrap: anywhere;
}
