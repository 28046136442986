@value clrWhite from '../../vars.css';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    flex-direction: row;
    align-content: space-between;
    margin: 0 0 25px 0;
    padding: 15px;
    background-color: #f4f5f7;
    border: 1px solid #dddddd;
}

.box {
    box-sizing: border-box;
    display: inline-block;
    line-height: 3.21rem;
    padding: 12px 0;
    margin: 0;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: clrWhite;
    text-align: center;
    vertical-align: middle;
    position: relative;
    white-space: nowrap;
    flex: 1 1 290px;
}

.boxes-1 .box {
    flex: 1 1 920px;
}

.boxes-2 .box,
.boxes-4 .box,
.boxes-6 .box {
    flex: 1 1 450px;
}

.title {
    display: block;
    font-size: 1.14rem;
    line-height: 1.57rem;
}

.number {
    display: block;
    font-size: 1.71rem;
    color: #079ce2;
    line-height: 2.14rem;
}

.promoted {
    background-color: #079ce2;
    float: right;
}


.primary .title,
.primary .number,
.promoted .title,
.promoted .number {
    color: #ffffff;
}

.boxes-4 .primary,
.boxes-4 .promoted {
    margin-right: 0;
}

.boxes-5 .primary,
.boxes-5 .promoted {
    height: 169px;
    float: right;
    padding-top: 60px;
}
