@value clrBorder from '../../vars.css';

.root {
    padding: 30px 0 0 0;
    overflow: hidden;
    border-top: 1px solid clrBorder;
    margin: 5px 0 0 0;
    text-align: right;
}

.small {
    padding-top: 24px;
}

.tiny {
    padding-top: 15px;
}

.leftButton {
    float: left;
}

.topMargin {
    margin-top: 30px;
}

.root > * {
    margin-left: 12px;
}

.root > *:first-child {
    margin-left: 0;
}
