.link {
    text-decoration: none;
    text-align: center;
    position: relative;
    display: inline-block;
    margin: 2px 0 0 10px;
    height: 3.929rem;
    line-height: 3.929rem;
}

.icon {
    font-size: 2.214rem;
    display: inline-block;
}

.number {
    color: black;
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translate(-50%, 0);
}
