@import "src/styles/config";
@value clrText, clrError, clrSuccess, clrBorder, clrTundora, clrYellow from '../../vars.css';

.wrapper {
    display: inline-block;
    font-size: 0.86rem;
    border-radius: 11px;
    padding: 1px 10px;
    margin-right: 15px;
}

.bordered {
    border: 1px solid transparent;
}

.gray {
    background: $clrBorder;
    color: $clrText;

    &.bordered {
        border-color: $clrBorder;
        background: transparent;
    }
}

.success {
    background: $clrSuccess;
    color: #fff;

    &.bordered {
        border-color: $clrSuccess;
        color: $clrSuccess;
        background: transparent;
    }
}

.error {
    background: $clrError;
    color: #fff;

    &.bordered {
        border-color: $clrError;
        color: $clrError;
        background: transparent;
    }
}

.primary {
    color: #fff;
}

.tertiary {
    background: $clrBorder;
    color: $clrTundora;

    &.bordered {
        border-color: $clrBorder;
        background: transparent;
    }
}

.warning {
    background: $clrYellow;
    color: #fff;

    &.bordered {
        border-color: $clrYellow;
        color: $clrYellow;
        background: transparent;
    }
}
