@import "src/styles/config";

.wrapper {
    display: flex;
    flex-direction: row;
}

.item {
    flex: 1 1 auto;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    vertical-align: middle;
    background: $clrBackground2;
    color: $clrGray;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.itemActive {
    background: $clrDarkGray;
    color: $clrWhite;
}

.icon {
    margin-right: 12px;
}

.compact {
    border: 1px solid $clrBorder;
    border-radius: 4px;

    .item {
        background: transparent;
        padding: 0 12px;

        svg g[fill] {
            fill: $clrBorder !important;
        }
    }

    .itemActive {
        background: $clrBorderLight;
        color: $clrDarkBlue;
        box-shadow: inset 0 1px 3px 0 $clrDarkBlue;

        svg g[fill]{
            fill: $clrDarkBlue !important;
        }
    }
}
