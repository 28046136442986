@import "src/styles/config";

.details {
    background: $clrWhite;
    padding: 20px;
    border: 1px solid $clrBorder;
}

.actions {
    padding: 10px 0;
    text-align: right;
}
