.icon {
    font-family: 'partnermarketing';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.icon.dark {
    color: #333;
}

.icon-account:before {
    content: '\e600';
}

.icon-archive:before {
    content: '\e601';
}

.icon-arrow-down:before {
    content: '\e602';
}

.icon-arrow-left:before {
    content: '\e603';
}

.icon-arrow-right:before {
    content: '\e604';
}

.icon-arrow-up:before {
    content: '\e605';
}

.icon-bg-circle:before {
    content: '\e606';
}

.icon-bg-square:before {
    content: '\e607';
}

.icon-bg-triangle:before {
    content: '\e608';
}

.icon-calendar:before {
    content: '\e609';
}

.icon-cross:before {
    content: '\e60a';
}

.icon-disabled:before {
    content: '\e60b';
}

.icon-dollar {
    width: 12px;
}

.icon-dollar:before {
    content: '$';
    font-size: 1.1em;
    padding-left: 2px;
}

.icon-download:before {
    content: '\e60c';
}

.icon-edit-colour:before {
    content: '\e60d';
}

.icon-edit-image:before {
    content: '\e60e';
}

.icon-edit-link:before {
    content: '\e60f';
}

.icon-edit-text:before {
    content: '\e610';
}

.icon-edit:before {
    content: '\e611';
}

.icon-exclamation:before {
    content: '\e612';
}

.icon-graph:before {
    content: '\e613';
}

.icon-info:before {
    content: '\e614';
}

.icon-loading:before {
    content: '\e615';
}

.icon-lock:before {
    content: '\e616';
}

.icon-new:before {
    content: '\e617';
}

.icon-plus:before {
    content: '\e618';
}

.icon-publish:before {
    content: '\e619';
}

.icon-remove:before {
    content: '\e61a';
}

.icon-save-exit:before {
    content: '\e61b';
}

.icon-tick:before {
    content: '\e61c';
}

.icon-trash:before {
    content: '\e61d';
}

.icon-unarchive:before {
    content: '\e61e';
}

.icon-updated:before {
    content: '\e61f';
}

.icon-view:before {
    content: '\e620';
}

.icon-zoom-in:before {
    content: '\e621';
}

.icon-sign-in:before {
    font-family: 'FontAwesome';
    font-size: 1em;
    vertical-align: middle;
    content: '\f090';
}

.icon-user-plus:before {
    font-family: 'FontAwesome';
    font-size: 1em;
    vertical-align: middle;
    content: '\f234';
}

.icon-signal:before {
    font-family: 'FontAwesome';
    font-size: 1em;
    vertical-align: middle;
    content: '';
}

.icon-unlink:before {
    font-family: 'FontAwesome';
    font-size: 1em;
    vertical-align: middle;
    content: '\f127';
}

.icon-facebook:before {
    content: '\e623';
}

.icon-linkedin:before {
    content: '\e624';
}

.icon-twitter:before {
    content: '\e625';
    font-size: 0.95em;
}

.icon-googleplus:before {
    content: '\e900';
}

.icon-blog:before {
    content: '\e901';
}

.icon-instagram:before {
    content: '\e902';
}

.icon-pinterest:before {
    content: '\e903';
}

.icon-vimeo:before {
    content: '\e904';
}

.icon-xing:before {
    content: '\e905';
}

.icon-youtube:before {
    content: '\e906';
}

.icon-wechat:before {
    content: '\e90c';
}

.icon-weibo:before {
    content: '\e90d';
}

.icon-youku:before {
    content: '\e90e';
}

.icon-merge:before {
    content: '\e907';
}

.icon-asterisk:before {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    content: '*';
}

.icon-notebook:before {
    content: '\e908';
}

.icon-bell:before {
    content: '\e909';
}

.icon-workflow:before {
    content: '\e90A';
}

.icon-settings:before {
    content: '\e90b';
}

.icon-copy:before {
    content: '\e914';
}

.icon-global:before {
    content: '\e917';
}

.icon-drag:before {
    content: '\e622';
}

.icon-basket:before {
    content: '\e916';
}

.icon-mail:before {
    content: '\e91b';
}

.icon-globus:before {
    content: '\e90f';
}

.icon-question:before {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em !important;
    content: "?";
}

.icon-knowledgebase {
    margin: 0;
    background: transparent url("../InfoLink/knowledgebase.png") no-repeat;
    background-size: contain;
    width: 23px;
    height: 23px;
    display: block;
}

.icon-grid:before,
.icon-two-col:before,
.icon-more:before {
    content: "\00a0";
    background: transparent 50% 50% url("./svg/more.svg") no-repeat;
    background-size: contain;
    display: inline-block;
    width: 12px;
    height: 12px;
}

.icon-grid:before {
    background-image: url("./svg/grid.svg");
}

.icon-two-col:before {
    background-image: url("./svg/two-col.svg");
}



.icon-file:before {
    content: "\e919";
}

.icon-category:before {
    content: "\e91a";
}

.icon-semi-global:before {
    content: "\e918";
    font-size: 1.29rem;
}

.icon-upload:before {
    content: "\e910";
}

.icon-circle-info:before {
    content: '\e614';
    background-color: #23323B;
    color: #ffffff;
    border-radius: 50%;
    padding: 3px;
    font-size: 0.7rem;
}

.icon-height:before {
    content: '\e917';
    transform: rotate(90deg);
    display: inline-block;
}

.icon-changes {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #dd8524;
}
