@import "src/styles/config";

.wrapper {
    border: none;

    .title {
        padding: 0 0 0 10px;

        & + .body {
            padding: 20px 0;
            border: none;
        }
    }

    .button {
        width: 35px;
        height: 35px;
    }

    &.bordered {
        .title {
            padding: 15px 20px;
            & + .body {
                padding: 20px;
                border: 1px solid $clrBorder;
            }
        }

        .button {
            width: 50px;
            height: 50px;
            margin: -15px -20px -15px 0;
        }
    }
}
