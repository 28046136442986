.barWrapper {
    display: flex;
    background: #23323b;
    color: #fff;
    padding: 9px 0;
}

.barWrapperContainer {
    display: flex;
    vertical-align: center;
    justify-content: space-between;
}

.iconBlock {
    margin-right: 17px;
}

.info {
    display: flex;
    align-items: center;
}
