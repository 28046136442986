.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputWrapper {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.input {
    width: 30px;
    text-align: center;
    padding: 1px 4px;
    margin-right: 5px;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
