@import "src/styles/config";

.wrapper {
    background: $clrGray1;
    padding: 15px;
    border-radius: 4px;
}

.message {
    line-height: 1.5rem;
    margin-bottom: 0;
    color: $clrGray;
    display: flex;
    align-items: flex-start;
}

.text {
    flex: 1;
}

.title {
    margin-bottom: 5px !important;
}

.icon {
    float: left;

    & + .message {
        margin-left: 35px;
    }
}

.icon-green {
    color: $clrSuccess;
}

.icon-black {
    color: $clrBlack;
}
