.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    padding: 15px 0 0;
}

.actions {
    display: flex;
}

.action {
    margin: 0 10px;
}

.action:last-child {
    margin: 0;
}
