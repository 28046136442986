@import '../../styles/config';

$bigButtonPadding: 4px 25px;
$clrWhiteBtn: #fff;
$clrGrayBtn: #ccc;
$clrWarningBtn: #dd8524;
$clrInfoBtn: #079ce2;
$clrErrorBtn: #d9534f;
$clrSuccessBtn: #5cb85c;
$clrCrimsonBtn: #f04187;
$clrNavyBtn: #23323b;

.button {
    font-size: 1rem;
    line-height: 1.79;
    color: $clrWhite;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    background-clip: padding-box;
    box-sizing: content-box;
    padding: 10px 14px;
    margin: 0 0 0 10px;
    display: inline-flex;
    transition: all 0.25s ease-in-out;
    user-select: none;
    border: none;
    word-spacing: normal;
    align-items: center;
    justify-content: center;
}

.button:not(.disabled):hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 2px 0 inset, rgba(0, 0, 0, 0.1) 0 0 0 100px inset !important;
}

.button.noMargin {
    margin: 0;
}

.button.small {
    padding: 6px 11px;
    font-size: 0.86rem;
    border-radius: 3px;
}

.button.tiny {
    padding: 1px 7px;
    font-size: 0.86rem;
    border-radius: 3px;
    min-height: 27px;

    &.iconOnly {
        min-height: 24px;
    }
}

.button.xxs {
    padding: 1px 5px;
    font-size: 0.66rem !important;
    border-radius: 3px;
    min-height: 18px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button .icon {
    margin: 0 7px 0 0;
    align-self: center;
    pointer-events: none;
}

.button.iconOnly .icon {
    margin: 0;
}

.button .icon.rightIcon {
    margin: 0 0 0 7px;
}

.button-white {
    background: $clrWhiteBtn;
    color: #000;
}
.button-gray {
    background: $clrGrayBtn;
    color: #4d4d4d;
}
.button-gray.bordered {
    background: transparent;
    box-shadow: inset 0 0 0 1px #d8d8d8;
    border: none;
}
.button-warning {
    background: $clrWarningBtn;
}
.button-crimson {
    background: $clrCrimsonBtn;
}
.button-warning.bordered {
    color: $clrWarningBtn;
    border-color: $clrWarningBtn;
}
.button-error {
    background: $clrErrorBtn;
}
.button-error.bordered {
    color: $clrErrorBtn;
    border-color: $clrErrorBtn;
}
.button-success {
    background: $clrSuccessBtn;
}
.button-success.bordered {
    color: $clrSuccessBtn;
    border-color: $clrSuccessBtn;
}
.button-info {
    background: $clrInfoBtn;
}
.button-info.bordered {
    color: $clrInfoBtn;
    border-color: $clrInfoBtn;
}
.button-navy {
    background: $clrNavyBtn;
}
.button-none {
    color: #797979;
    text-decoration: underline;
    background: transparent;
}
.button-none:not(.disabled):hover {
    box-shadow: none !important;
}

.bordered {
    background: transparent;
    border-width: 1px;
    border-style: solid;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.button .icon.iconSpin:before {
    animation: spin 1s infinite linear;
    display: inline-block;
}
