.progressWrapper {
    padding: 15px 20px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background-clip: padding-box;
}

.column {
    display: flex;
    align-items: center;
}

div.progressBar {
    flex: 1;
}

div.progressBar .outer {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background-clip: padding-box;
    height: 13px;
    background-color: #f2f2f2;
    margin: 6px;
    -moz-box-shadow: inset 0 0 5px #cccccc;
    -webkit-box-shadow: inset 0 0 5px #cccccc;
    box-shadow: inset 0 0 5px #cccccc;
}

div.progressBar .inner {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background-clip: padding-box;
    height: 13px;
    position: relative;
    background-image: url(./progress-bar.png);
    background-repeat: repeat-x;
}
div.progressBar .inner.static {
    background-image: none;
}

div.progressBar span.percentage {
    font-size: 1.14rem;
    line-height: 2rem;
    display: block;
    text-align: left;
}
