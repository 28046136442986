.empty {
    text-align: center;
    padding: 40px;
}

.alignLeft {
    text-align: left;
    padding: 0;
}

.empty p {
    margin: 30px 0;
}

.link {
    color: #3e8ddd;
    text-decoration: none;
    display: inline-block;
    margin-top: 15px;
}

.group {
    margin-top: 30px;
}

.button {
    margin-top: 25px;
}

.noMarginLeft {
    margin-left: 0;
}

.text {
    margin-top: 25px;
}

.infoBlock {
    background-color: #f4f5f7;
}
