@value headerLineHeight: 26px;

.wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 20px;
    text-align: left;
    line-height: headerLineHeight;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    position: relative;
    border-bottom: 1px solid #fff;
    z-index: 1;
}

.row {
    display: flex;
}

.noTitle {
    border-bottom: none;
    z-index: 3;
}

.icon {
    margin-right: 12px;
}

.iconWarning {
    color: #dd8524;
}
