@value clrBorder, clrText, clrWhite from '../../vars.css';

.hidden {
    display: none;
}

.menuDropDown {
    float: none;
    min-width: auto;
    border: none;
    border-radius: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 25px calc((100% - 970px) / 2) 0;
    margin-top: 1px;
    justify-content: flex-start;
    background-color: #f4f5f7;
    box-shadow: 0 2px 4px 0 #d8d8d8;
}

.noPadding {
    padding: 0;
}

.mainItems > li {
    list-style: none;
    display: inline-block;
}

.mainItems > li .mainItem,
.mainItems > li > a {
    border-right: 1px solid #ffffff;
    color: #ffffff;
    font-size: 1rem;
    line-height: 3.14;
    padding: 0 10px;
    text-decoration: none;
    display: inline;
}

.mainItems > li:last-child .mainItem,
.mainItems > li:last-child > a {
    border-right: none;
}

.mainItems > li.current:after {
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top-width: 6px;
    border-top-style: solid;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    left: 50%;
    margin-left: -6px;
    top: calc(100% + 1px);
}

.globalLogoWrapper {
    min-height: 130px;
}

.globalLogo {
    display: table-cell;
    vertical-align: middle;
    height: 130px;
}

.dashboardLogo {
    display: block;
}

.logoContainer {
    position: relative;
}

.helpDropDown {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 2;
}

.topNav {
    background-color: #f4f5f7;
}

.topNav .topNavContainer {
    text-align: right;
}

.topNav .topNavContainer button {
    border-radius: 0;
    box-shadow: unset;
    border-left: 1px solid #c1c4d2;
    margin: 0;
    font-weight: bold;
    padding: 6px 11px;
}

.topNav .topNavContainer button:hover {
    box-shadow: unset !important;
}

.topNav .topNavContainer .topNavDropDown button {
    border-right: 1px solid #c1c4d2;
}

.topNav .topNavContainer .topNavDropDown ul {
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(204, 204, 204, 0.5);
    border: 1px solid #c1c4d2;
    min-width: 100%;
    right: 0;
    left: 0;
    box-sizing: border-box;
    padding: 5px 0;
}

.topNav .topNavContainer .topNavDropDown > div > div {
    margin: 0;
    padding-top: 0;
}

.topNav .topNavContainer .topNavDropDown ul > li > a {
    padding: 2px 10px 2px 10px;
    text-align: right;
}

.topNav .topNavContainer > div {
    position: relative;
}

.topNavDropDown {
    display: inline-block;
}

.userDropDownIcon {
    margin-right: 8px;
}

.userDropDownArrow {
    margin-left: 8px;
}

.userDropDown .userDropDownButton {
    margin: 0;
}

.navWrapper {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    position: relative;
}

.nav {
    margin-left: -10px;
}

:global(#react-root) .dark {
    margin: 0.5em 0;
    border: none;
    border-top: 1px solid clrBorder;
}

.grouping {
    padding: 3px 20px;
    font-size: 0.9em;
    color: #C1C4D2;
    font-weight: bold;
    cursor: default;
}

.link {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: nowrap;
    border-right: 1px solid #ffffff;
    font-size: 1rem;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
}

.arrowRight {
    display: inline-block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #666666 transparent;
    transform: rotate(90deg);
    margin-left: 5px;
}

.linkDescription {
    padding: 0 5px 5px;
}

.sideMenu {
    display: flex;
    width: 100%;
}

.sideMenu .sideMenuLeft {
    flex: 1 1;
    padding: 40px 0;
    box-sizing: border-box;
}

.sideMenu .sideMenuLeft ul {
    height: 100%;
}

.sideMenu .sideMenuLeft ul .leftSideItem {
    font-size: 1.142857143rem;
    padding: 10px 50px;
    cursor: pointer;
}

.sideMenu .sideMenuRight {
    flex: 3.4 1;
}

.sideMenu .sideMenuColumns {
    display: flex;
    justify-content: flex-start;
}

.sideMenu .sideMenuColumns .sideMenuColumn {
    margin-right: 6rem;
}

.sideMenu .sideMenuColumns .sideMenuColumn .rightSideLink {
    margin-top: 12px;
}

.menuSection {
    background-color: clrWhite;
    box-sizing: border-box;
    padding: 25px 21px;
    width: 290px;
    -webkit-box-shadow: 0 2px 4px 0 #d8d8d8;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    margin-bottom: 28px;
}

.menuSection + .menuSection {
    margin-left: 35px;
}

.menuSection dl {
    margin-bottom: 18px;
    padding: 0 5px;
}

.menuSection dl dt {
    margin-bottom: 11px;
}

.menuSection dl dd {
    line-height: 1.43;
}

.menuSection a {
    display: flex;
    align-items: center;
    padding: 5px;
    clear: both;
    line-height: 1.43;
    white-space: pre-wrap;
}

.menuSection .columns-3 {
    display: flex;
    justify-content: space-between;
}

.menuSection .columns-3 > ul {
    width: 28%;
}

.menuSection:nth-child(4) {
    margin-left: 0;
}

.widSection {
    width: 940px;
}

.forSideMenuRight {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 40px 55px 25px;
}

.forSideMenuRight dt {
    font-weight: normal !important;
}

.flexRow {
    display: flex;
    width: 100%;
}
