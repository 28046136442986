.title {
    font-size: 16px;
    font-weight: bold;
    color: #172b4d;
    margin-bottom: 20px;
}

.wrapper {
    padding-bottom: 40px;
    margin-bottom: 40px;
    background-image: linear-gradient(to right, #999 20%, rgba(255, 255, 255, 0) 20%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;

    &.last {
        margin: 0;
    }

    .listItem {
        font-size: 14px;
        margin-bottom: 20px;
        display: flex;

        .label {
            font-weight: 600;
            min-width: 280px;
            flex: 30%;
            overflow-wrap: break-word;
        }

        .value {
            flex: 70%;
            margin-left: 20px;
        }
    }
}
