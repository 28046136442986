@import "src/styles/config";

.wrapper {
    &.borderTop {
        margin: 10px 0 0;
        padding: 35px 0 0;
        border-top: 1px solid $clrBackground2;
    }

    h2 + p {
        margin-bottom: 20px;
    }

    & > p {
        margin-bottom: 30px;
    }

    .tipInfo {
        color: $clrWhite;
        margin-left: 5px;
        vertical-align: text-top;
    }

    .tipInfoIcon:before {
        color: $clrWhite;
    }
}

.labelOffset {
    padding-top: 39px;
}
