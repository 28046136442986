$topBarHeight: 72px;

.wrapper {
    height: 100vh;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .topBar {
        box-shadow: 0 2px 11px 0 #ebecf0;
        background-color: #f4f5f7;
        position: relative;
        z-index: 1;
        height: $topBarHeight;
    }

    .subContent {
        flex: 1;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: calc(100% - #{$topBarHeight});
    }
}

html body .wrapper {
    b, strong {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: bold;
    }

    em {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-style: italic;
        font-weight: inherit;
    }
}
