.wrapper {
  display: inline-block;
  position: relative;
}

.item {
  padding: 4px 0;
}

.icon {
  margin: 0 7px 0 -7px;
}

.menu {
  left: 10px;
  right: auto !important;
  margin-top: 2px !important;
}

.button {
  :global(.btn-text) {
    font-weight: bold;
    font-size: 120%;
    margin-top: -6px;
  }
}
