@value clrBorder, clrLightGray, clrError from '../../vars.css';

.wrapper {
    padding-bottom: 10px;
}

.languageWrapper {
    padding-bottom: 15px;
}

.addLanguage {
    padding: 10px;
    background-color: clrLightGray;
    margin-bottom: 24px;
}

.noPadding {
    padding: 0;
}

.right {
    float: right;
}

.actions {
    float: right;
}

.remove {
    margin: 15px;
    cursor: pointer;
}

.topLine {
    height: 50px;
    background-color: clrLightGray;
    border: 1px solid clrBorder;
    box-sizing: border-box;
}

.topLine h4 {
    font-size: 1rem;
    font-weight: normal;
    float: left;
    margin: 0 0 0 15px;
    line-height: 3.57rem;
    max-width: 80%;
}

.content {
    padding: 20px 30px;
    border: 1px solid clrBorder;
    border-top: none;
}

.arrow {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 3.57rem;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: -1px -1px 0 0;
    cursor: pointer;
}

.hasError .topLine,
.hasError .content,
.hasError .arrow {
    border-color: clrError;
}
