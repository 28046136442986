.tabs {
    margin-bottom: 10px;
}

.actions {
    padding-top: 10px;
    text-align: right;
}

.imagePreview {
    text-align: center;
    margin-bottom: 10px;

    img {
        max-width: 100%;
        max-height: 300px;
    }

    & + div label {
        font-weight: bold;
    }
}

button.imagePreview {
    cursor: pointer;
    display: block;
    border: none;
    background: transparent;
}

.text {
    margin-bottom: 10px;
}

.dark {
    background: #000;
}

.hidden {
    display: none;
}
