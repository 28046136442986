@value clrError, clrSuccess, clrOrange from '../../vars.css';

.wrapper {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
}

.wrapper.dark {
    padding: 13px 20px;
}

.wrapper.dark > div {
    color: #fff;
}

.icon {
    margin-right: 10px;
}

.warning {
    color: clrOrange;
}

.success {
    color: clrSuccess;
}

.error {
    color: clrError;
}
