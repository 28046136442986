@import "../../styles/config";

.wrapper {
    margin: 0 0 35px 35px;
    padding: 20px;
    box-shadow: 2px 2px 6px 0 $clrBorder1;
    border: solid 1px $clrBorderLight;
    background-color: $clrWhite;
    text-align: center;
    flex: 1;
    width: 100%;
    min-height: 120px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover .description {
        display: flex;
    }
}

.imgWrapper {
    position: relative;
    overflow: hidden;
    height: 130px;
    width: 100%;

    & > img {
        max-height: 130px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    & + .title {
        margin-top: 15px;
    }
}


.title {
    font-size: 1.14rem;
    line-height: 1.38;
    font-weight: bold;
}

.description {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #6b778c;
    color: $clrWhite;
    text-align: center;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.43;
}
