.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrapper > *:not(:first-child) label {
    width: auto;
    min-width: 1px;
    margin-left: 10px;
    margin-right: 10px;
}
