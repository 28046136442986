@value clrBorder from '../../vars.css';

.h2 {
    width: 259px;
    height: 1.43rem;
    font-size: 1.14rem;
    font-weight: bold;
    line-height: 1.25;
    margin: 30px 0 25px;
}

.languageTitle {
    margin: 0 0 25px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.43;
}

.dark {
    margin: 0.5em 0;
    border: none;
    border-top: 1px solid clrBorder;
}

.default {
    padding: 5px 0 25px;
    display: block;
}

.default input {
    margin: 4px 10px 0 2px;
    float: left;
}
