@import "src/styles/config";

.version {
  margin: -15px 14px -15px 0;
  padding: 15px 24px;
  border-right: 1px solid $clrBorder;
  display: flex;
  align-items: center;

  .input {
    min-width: 200px;
    & > div {
      padding-right: 0;
    }
  }

  &  > span {
    margin-right: 17px;
  }
}
