@import "src/styles/config";

.wrapper {
    flex:0 0 55px;
    width: 55px;
    box-shadow: 0 2px 10px 0 $clrBorder;
    background-color: $clrDarkGray;
    position: relative;
    z-index: 2;

    .link {
        display: block;
        width: 55px;
        height: 55px;
        line-height: 55px;
        color: $clrWhite;
        text-decoration: none;
        font-size: 20px;
        text-align: center;
        transition: background-color 0.2s;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-left-width: 0;
            border-left-color: $clrDarkGray;
            transition: right 0.2s, border-left-width 0.2s, border-left-color 0.2s;
        }
    }

    .active{
        position: relative;

        &::after {
            right: -9px;

            width: 0;
            height: 0;
            border-top: 27px solid transparent !important;
            border-bottom: 27px solid transparent !important;
            border-left: 9px solid transparent;
        }
    }
}
