.wrapper {
    position: relative;
    padding-right: 25px;
    display: inline-block;
}

.icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -9px;
}
