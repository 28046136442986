@value clrSuccess, clrError, clrInfo from '../../vars.css';

.alertWrapper {
    position: relative;
    z-index: 2;
}

.alert {
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.alert.success {
    color: clrSuccess;
}

.alert.error {
    color: clrError;
}

.alert.notify {
    color: clrInfo;
}

.right {
    margin-right: 15px;
    cursor: pointer;
    color: #ccc;
}

.wrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.message {
    margin: 10px 0 10px 10px;
    color: #cccccc;
}
