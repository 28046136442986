@value clrBorder: #ddd;
@value edgePadding: 20px;
@value topPadding: 4px;
@value topHeaderPadding: 10px;
@value searchLoaderSize: 20px;
@value headerHeight: 25px;

.datagridTable {
    position: relative;
    padding: 25px 0 0 0;
    border: 1px solid clrBorder;
    overflow: hidden;
    background: #fff;
}

.datagrid {
    width: 100%;
}

.datagridLoading {
    min-height: 4.286rem;
    font-size: 4.286rem;
}

.datagrid table {
    width: 100%;
    table-layout: auto;
    margin-bottom: 20px;
    font-size: 0.93rem;
}

.datagrid table tr {
    border: 1px solid clrBorder;
}

.datagrid table td,
.datagrid table th {
    border-left: 1px solid clrBorder;
    border-right: 1px solid clrBorder;
    padding: 0 10px;
    height: 48px;
    vertical-align: middle;
    text-align: left;
}

.datagridSwappable.datagrid table {
    table-layout: fixed;
}
.datagridSwappable.datagrid table tbody tr {
    background: #fff;
}

.datagridSwappable.datagrid table tr th,
.datagridSwappable.datagrid table tr td {
    border-left: none;
    border-bottom: none;
    display: flex;
    align-items: center;
    min-height: 48px;
}

.datagridSwappable.datagrid table tbody.body tr .col {
    height: auto;
    min-height: 3.929rem;
}

.datagridSwappable.datagridLoading table tr,
.datagridSwappable.datagridLoading table tr th,
.datagridSwappable.datagridLoading table tr td {
    flex: 1;
}

.datagridSwappable.datagrid table tr th:first-child,
.datagridSwappable.datagrid table tr td:first-child {
    padding-left: 30px;
    position: relative;
    border-left: 1px solid clrBorder;
}

.datagridSwappable table tr {
    display: flex;
    margin-bottom: -1px;
    border: none;
    border-top: 1px solid clrBorder;
}

.datagridSwappable table tr[data-rbd-placeholder-context-id] {
    border: none;
}

.datagridSwappable table .colSwap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20px;
    font-size: 0.71rem;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #ccc;
}

.datagridHeader .sortRow th {
    height: 20px !important;
    min-height: auto !important;
    position: relative;
    background: #f4f5f7;
}

.datagridHeader .col.colSortable {
    cursor: pointer;
}

.datagridHeader .col .sortIndicator {
    display: inline-block;
    position: absolute;
    right: 7px;
    top: 1px;
}

.subHeader {
    background-color: #f4f5f7;
}

.datagrid tbody.body {
    width: 100%;
}

.datagrid tbody.body tr td {
    border-bottom: 1px solid clrBorder;
}

.datagrid tbody.body tr .col {
    height: 3.929rem;
    vertical-align: middle;
}

.colActions {
    width: 1px;
    white-space: nowrap;
}

.actionButton {
    margin-left: 7px;
}

.actionButton:first-child {
    margin-left: 0;
}

.batchCol {
    width: 15px;
}

td.batchCol {
    cursor: pointer;
}

.batchActions {
    display: flex;
    align-items: center;
    padding: 0 0 15px 0;
    height: 27px;
}

.batchActionsWrapper {
    margin-left: 20px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
