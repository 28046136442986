@import "src/styles/config";

.draggable {
    display: flex;
    align-items: stretch;
    margin-bottom: 10px;
}

.handle {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $clrBorder;
    font-size: 8px;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.item {
    flex: 1;
}

.placeholder {
    border: 2px dashed $clrBorder;
}
