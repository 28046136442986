@value clrBorder from '../../vars.css';

.imagePreview {
    max-width: calc(100% - 160px);
    margin-left: 160px;
}

.h2 {
    width: 259px;
    height: 20px;
    font-size: 1.14rem;
    font-weight: bold;
    line-height: 1.25;
    margin: 30px 0 25px;
}

.languageTitle {
    margin: 15px 0 0 0;
    padding: 0 0 0 15px;
    font-size: 1rem;
    font-weight: normal;
    height: 3.571rem;
    line-height: 3.571rem;
    background-color: #f4f5f7;
    box-sizing: border-box;
}

.languageTitle button {
    float: right;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: #f4f5f7;
    border: 1px solid #d8d8d8;
}

.languageFields {
    border: 1px solid #c1c4d2;
    padding: 27px 29px 28px 25px;
}

.dark {
    margin: 0.5em 0;
    border: none;
    border-top: 1px solid clrBorder;
}

.default {
    padding: 5px 0 25px;
    display: block;
}

.default input {
    margin: 4px 10px 0 2px;
    float: left;
}
