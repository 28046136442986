@import 'src/styles/config';

$clrPassStep: #eef8ee;

.wrapper {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    margin: 25px 0;
}

.step {
    flex: 1 1 auto;
    margin-right: 16px;
    background-color: $clrWhite;
    text-align: center;
    vertical-align: middle;
    position: relative;
    font-weight: bold;

    &.pass {
        .arrowTopLeft,
        .arrowTopRight,
        .arrowBottomLeft,
        .arrowBottomRight {
            background-color: $clrPassStep;
            border-color: $clrPassStep;
        }
    }

    &:first-child {
        .arrowTopLeft {
            transform: none;
            border-radius: 10px 0 0 0;
        }
        .arrowBottomLeft {
            border-radius: 0 0 0 10px;
            transform: skewX(0.1deg);
        }
    }
    &:last-child {
        margin-right: 0;
        .arrowTopRight {
            transform: none;
            border-radius: 0 10px 0 0;
        }
        .arrowBottomRight {
            transform: skewX(0.1deg);
            border-radius: 0 0 10px 0;
        }
    }
}

.content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.arrowTopLeft,
.arrowTopRight,
.arrowBottomLeft,
.arrowBottomRight {
    position: absolute;
    left: 0;
    right: 30%;
    background: $clrLightGray;
    border: 0 solid $clrBorder;
}

.arrowTopLeft,
.arrowTopRight {
    border-top-width: 1px;
    top: 0;
    bottom: 50%;
    transform: skewX(20deg);
}

.arrowBottomLeft,
.arrowBottomRight {
    border-bottom-width: 1px;
    top: 50%;
    bottom: 0;
    transform: skewX(-20deg);
}

.arrowBottomLeft,
.arrowTopLeft {
    border-left-width: 1px;
    left: 0;
    right: 30%;
}

.arrowBottomRight,
.arrowTopRight {
    border-right-width: 1px;
    left: 30%;
    right: 0;
}
