@value clrBackground2, clrLightGreen, clrGreen, clrLightRed, clrRed, clrYellow, clrYellowLight from '../../vars.css';

.wrapper {
    margin-bottom: 15px;
    border: 1px solid clrBackground2;
}

.title {
    padding: 11px 25px 14px;
    background-color: #f4f5f7;
    color: #6b778c;
    display: flex;
    align-items: center;
    position: relative;
}

.details {
    float: right;
}

.title-success {
    background: clrLightGreen;
    color: clrGreen;
    padding: 15px;
    font-weight: bold;
}

.title-error {
    background: clrLightRed;
    color: clrRed;
    padding: 15px;
    font-weight: bold;
}

.title-warning {
    background: clrYellowLight;
    color: clrYellow;
    padding: 15px;
    font-weight: bold;
}

.titleText {
    flex: 1;
}

.titleDark .titleText {
    color: #fff;
}

.body {
    padding: 22px 25px;
}

.title + .body {
    border-top: 1px solid clrBackground2;
}

.icon {
    float: left;
    margin-right: 10px;
}

.wrapper.radio {
    background: #f2f2f2;
}

.wrapper.radio .title {
    background: #FFFFFF;
    border: none;
    padding: 15px 25px 15px 50px;
    position: relative;
    font-size: 1.14rem;
    line-height: 1.4;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.wrapper.radio .checkbox {
    position: absolute;
    float: left;
    margin: 2px 0 0 -30px;
}

.wrapper.radio .check {
    width: 17px;
    height: 17px;
    position: absolute;
    border-radius: 8px;
    border: 1px solid clrBackground2;
    box-sizing: border-box;
    float: left;
    margin: 2px 0 0 -30px;
}

.wrapper.radio .checked {
    border-width: 5px;
}

.actions {
    display: flex;
    align-items: center;
}
