body.dialogShown {
    overflow: hidden;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1001;
}

.overlay.sample {
    position: relative;
    height: 400px;
}

.dialog {
    position: fixed;
    z-index: 1000;
    width: 470px;
    padding: 25px;
    margin-left: -235px;
    left: 50%;
    top: 35%;
    border-radius: 4px;
    background-clip: padding-box;
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.8);
}

.dialog.sample {
    position: absolute;
}

.dialog.warning {
    color: #dd8524;
}

.dialog.info {
    color: #079ce2;
}

.dialog.error {
    color: #d6514b;
}

.dialog.success {
    color: #5cb85c;
}

.icon {
    float: left;
}

.text {
    padding-left: 45px;
    color: #fff;
    font-size: 1.14rem;
    line-height: 1.81;
    padding-bottom: 10px;
    margin: 0 0 10px 0;
}

.title {
    padding: 4px 0 0 45px;
    font-size: 1rem;
    color: #fff;
    line-height: 1.43;
    font-weight: bold;
}

.message {
    color: #fff;
    font-size: 1rem;
    line-height: 1.43;
    font-style: italic;
    padding-top: 14px;
    clear: both;
    margin-top: 24px;
    border-top: 1px solid #fff;
}

.buttons {
    padding-top: 15px;
    text-align: right;
}
