html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('./fonts/font-awesome/fontawesome-webfont.eot?v=#{$fa-version}');
    src: url('./fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('./fonts/font-awesome/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('./fonts/font-awesome/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('./fonts/font-awesome/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('./fonts/font-awesome/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'partnermarketing';
    src: url('./fonts/partnermarketing.eot');
    src: url('./fonts/partnermarketing.eot#iefix') format('embedded-opentype'),
    url('./fonts/partnermarketing.woff') format('woff'), url('./fonts/partnermarketing.woff2') format('woff2'),
    url('./fonts/partnermarketing.ttf') format('truetype'),
    url('./fonts/partnermarketing.svg#partnermarketing') format('svg');
    font-weight: normal;
    font-style: normal;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
body {
    margin: 0;
    padding: 0;
    min-width: 970px;
    background-color: #f2f2f2;
    height: 100%;
}

body {
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    /*
  TODO: move this back after migration.
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
    color: #656565;
    min-height: 100%;
}

#react-root {
    min-height: 100vh;
    display: none;
    flex-direction: column;
    background-color: #fff;
}

#react-root .root-content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

strong {
    font-weight: bold;
}

sup {
    vertical-align: super;
    font-size: smaller;
}

.container {
    width: 970px;
    padding: 0 15px;
    margin: 0 auto;
}

.link, button.link {
    color: #f27f38;
}

.disabled {
    opacity: 0.3 !important;
    cursor: default !important;
}

.react-tooltip {
    max-width: 250px;
    text-align: left;
    padding: 6px 8px;
    font-size: 0.86rem;
    font-weight: bold;
    line-height: 1.5;
    filter: drop-shadow(0px 2px 1px #c1c4d2);
}

.react-tooltip.small {
    max-width: 150px;
}

.react-tooltip.light:after {
    border-top-color: #ebecf0 !important;
}

.react-tooltip.light {
    background-color: #ebecf0;
    color: #6b778c;
}

#react-root .sub-title, #modal-root .sub-title {
    font-size: 1.14rem;
    font-weight: bold;
    line-height: 1.25;
    color: #172b4d;
    margin: 0 0 20px 0;
}

#react-root .flex-space-between, #modal-root .flex-space-between {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#react-root .section-title, #modal-root .section-title {
    font-size: 1.14rem;
    font-weight: normal !important;
    line-height: 1.25;
    color: #172b4d;
    margin: 14px 0;
}

#react-root .list, #modal-root .list {
    list-style: disc;
    padding-left: 1.1rem;
}

@media only screen and (min-width: 992px) {
    .container {
        width: 970px;
    }
}

html .rw-widget > .rw-widget-container {
    border: none;
    background: transparent;
    width: auto;
}

html .rw-state-focus > .rw-widget-container:hover,
html .rw-state-focus > .rw-widget-container {
    box-shadow: none;
}

html .rw-widget-input {
    box-shadow: none;
    padding: 0 8px;
}

html .rw-multiselect .rw-select .rw-btn-select:empty:after {
    display: inline-block;
    color: inherit;
    font-family: RwWidgets;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    content: '\E803';
    box-sizing: border-box;
    opacity: 0.7;
}

html .rw-multiselect .rw-input-reset {
    padding: 5px;
}

html .rw-widget.rw-datetime-picker {
    height: 38px;
}

html .rw-widget > .rw-widget-picker {
    height: 36px;
    margin: -7px -8px;
    padding-right: 0;
}

html .rw-widget > .rw-widget-picker .rw-select {
    vertical-align: middle;
}

html .rw-calendar-month .rw-cell {
    vertical-align: middle;
}

html .rw-input[readonly] {
    cursor: pointer;
}

html .rw-multiselect-taglist {
    vertical-align: middle;
}

html .rw-multiselect-tag {
    margin-top: 0;
    text-align: left;
}

html .rw-multiselect .rw-input-reset[placeholder] {
    padding: 0 4px;
}

body.modal-shown {
    overflow: hidden;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-0 {
    margin-bottom: 0;
}

.centered {
    text-align: center;
}

#react-root hr, #modal-root hr {
    margin: 0 0 24px;
    border: none;
    border-top: 1px solid #ccc;
}

#react-root hr.dashed, #modal-root hr.dashed {
    border-top: 2px dashed #ccc;
}

#react-root p > span > br + br, #modal-root p > span > br + br {
    height: 10px;
    overflow: hidden;
    display: block;
    content: "";
}

/**
 * RichText component styles.
 */

.quill .ql-toolbar.ql-snow {
    border: none;
    padding: 0;
}

.quill .ql-toolbar.ql-snow .ql-formats {
    margin: 0 5px 0 0;
}

.quill .ql-toolbar.ql-snow .ql-formats button {
    background-color: #f7f7f9;
    padding: 4px 9px;
    font-size: 0;
    color: #333;
    border: 1px solid #ccc;
    height: 30px;
    line-height: 1.571rem;
    box-sizing: border-box;
    min-width: 33px;

    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    user-select: none;
}

.quill em {
    font-style: italic;
}

.quill .ql-toolbar.ql-snow .ql-formats button svg {
    height: 15px;
    width: 15px;
    float: left;
}

.quill .ql-toolbar.ql-snow .ql-formats button + button {
    margin-left: -1px;
}

.quill .ql-toolbar.ql-snow .ql-formats button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.quill .ql-toolbar.ql-snow .ql-formats button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.quill .ql-toolbar.ql-snow .ql-formats button:hover {
    background-color: #ebebeb;
    border-color: #adadad;
}

.quill .ql-toolbar.ql-snow .ql-formats button.ql-active {
    border-color: #adadad;
    background-color: #ebebeb;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    color: #333;
}

.quill .ql-toolbar.ql-snow .ql-formats button:hover .ql-stroke,
.quill .ql-toolbar.ql-snow .ql-formats button.ql-active .ql-stroke {
    stroke: #333;
}

.quill .ql-container.ql-snow {
    border: none;
}

.quill .ql-editor {
    font: 14px/1.5 Arial, 'Helvetica Neue', Helvetica, sans-serif;
    min-height: 120px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    color: #656565;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    box-shadow: inset 0 1px 1px #e9e7e7;
    border-radius: 4px;
    background-clip: padding-box;
    margin-top: 15px;
}

.quill .ql-tooltip {
    z-index: 2;
}

/**
 * End RichText component styles.
 */
