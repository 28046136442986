@import "src/styles/config";

.form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.wrapper {
    display: flex;
    flex: 1 1 auto;
}

.leftBar {
    flex: 0 0 250px;
    background: $clrWhite;
    box-shadow: 0 0 10px $clrBorderLight;
    color: $clrTundora;
    position: relative;
}

.template {
    flex: 1 1 auto;
}

:global(#template) {
    // Override reset css here.
    ol, ul {
        list-style: initial;
    }
}

.updatePreview {
    margin-left: 0;
}

.template.StaticBannerTemplate :global(#fakeTemplateHtmlElement) {
    margin: 60px 0 0 60px;
    position: relative;
}

.template.AnimatedBannerTemplate :global(#template),
.template.CoBrandedPdfTemplate :global(#template) {
    height: calc(100vh - 72px);
}

.template.AnimatedBannerTemplate :global(#template),
.template.CoBrandedPdfTemplate :global(#template) {
    display: flex;
}

.template.CoBrandedPdfTemplate.campaignEditor {
    margin: -40px;

    :global(#editCoBrandedPdfPreview) {
        margin-top: 15px;
    }

    :global(#template) {
        flex-direction: column;

        div[rel="head"] {
            display: none;
        }

        div[rel="body"] {
            flex: 1;
        }

        iframe {
            width: 100%;
            height: calc(100vh - 120px);
        }
    }
}


.template :global(#editCoBrandedPdfEditor),
.template :global(#editAnimatedBannerEditor) {
    padding: 30px 30px 0 30px;
    flex: 0 0 250px;
    background: #FFFFFF;
    box-shadow: 0 2px 10px #c1c4d2;
}

.template :global(#editCoBrandedPdfPreview),
.template :global(#editAnimatedBannerPreview) {
    flex: 1;
    margin-top: 30px;
    padding: 0 0 0 30px;
}

.template :global(#editCoBrandedPdfPreview) > h2,
.template :global(#editCoBrandedPdfEditor) > h2,
.template :global(#editAnimatedBannerPreview) > h2,
.template :global(#editAnimatedBannerEditor) > h2 {
    font-size: 1.21rem;
    margin: 0 0 10px 0;
    font-weight: 700;
}

.template :global(#editCoBrandedPdfPreview) {
    padding: 0;
    display: flex;
    flex-direction: column;

    h2 {
        padding-left: 30px;
    }
}

.template :global(#editCoBrandedPdfEditor) {
    display: flex;
    flex-direction: column;
    padding: 0;

    & h2 {
        margin-bottom: 10px;

        & + :global(.hr) {
            display: none;
        }
    }

    :global(.hr) {
        margin: 24px 0 19px;
        border: solid 1px $clrBorder;
    }

    .imageContainer {
        max-width: 100% !important;
        height: auto !important;

        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }

    :global(.editor-fields) {
        flex: 1;
        overflow-y: auto;
        padding: 30px 40px 30px 30px;
        width: 260px;
    }

    :global(.update-wrapper) {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 550px;
        padding: 15px;
        background-color: $clrGray1;
        box-shadow: 0 -2px 5px 0 rgba(193, 196, 210, 0.5);
        z-index: 3;
        text-align: right;
    }

    &:after {
        content: "";
        display: block;
        height: 64px;
    }
}

.template :global(#template [editable="true"]),
.template :global(#template [link-editable="true"]),
.template :global(#template [text-editable="true"]),
.template :global(#template) .editable,
.template :global(#template) .imageEditable {
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    & > .editableOverlay {
        border: 2px dashed $clrTundora;
    }

    &.active {
        & > .editableOverlay {
            border-color: $clrSuccess;
            z-index: 2;
            & > .iconHolder {
                background-color: $clrSuccess !important;
            }
        }
    }

    &.linkWithHTML {
        display: block;
        font-size: 0;
    }

    &.deleted {
        cursor: not-allowed;
    }

    &.notActive,
    &.deleted {
        & > .editableOverlay {
            border-color: $clrError;
            & > .iconHolder {
                &:not(.bottom) {
                    background-color: $clrError !important;
                }

                &.bottom {
                    cursor: pointer;
                }
            }
        }
    }

    &.disabledOverlay {
        & > .editableOverlay {
            border-color: $clrError;
        }
    }
}

.template :global(#template) {
    box-sizing: border-box;

    b, strong {
        font-weight: bold;
    }

    i, em {
        font-style: italic;
    }
}

.template .toggle {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border: 2px dashed $clrTundora;
    }
}

.template .editableOverlay {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .1);
}

.template :global(#template) span.editable {
    cursor: pointer;
    border: 2px dashed $clrTundora;
    background-color: rgba(0, 0, 0, .1);

    &.active {
        border-color: $clrSuccess;
    }

    .editableOverlay {
        position: relative;
        width: auto;
        height: auto;
        border: none;
    }
}


.template :global(#template) .iconHolder {
    position: absolute;
    z-index: 1;
    right: -20px;
    top: -2px;
    height: 18px;
    width: 18px;
    font-size: 0.857rem !important;
    line-height: 1.286rem !important;
    background-color: $clrTundora !important;
    color: $clrWhite !important;
    text-align: center !important;

    &.bottom {
        top: 16px;
    }

    .template :global(#template) .iconHolder.light {
        background-color: $clrWhite !important;
        color: $clrTundora !important;
    }

    &:not(.bottom) + .iconHolder {
        right: -38px;
        height: 19px;
        top: -3px;

        &.iconDisabled {
            top: 18px;
            right: -20px;
            background-color: $clrError !important;
        }
    }

    & > span {
        font-size: 0.857rem !important;
        line-height: 1.286rem !important;
    }
}

.template :global(#template) .editable .editable .iconHolder {
    right: -38px;
    top: -2px;
}

.globalIconDescription {
    display: flex;
    background-color: rgba(255, 255, 255, .2);
    margin-bottom: 15px;
    padding: 12px 10px 8px;
    align-items: flex-start;

    p {
        margin: -1px 0 0 10px;
        font-style: normal;
    }
}

.modalField {
    background: $clrGray2;
    padding: 10px 10px 3px;
    color: $clrBlack;
    border-radius: 4px;
    background-clip: padding-box;
    position: relative;

    & + .modalField {
        margin-top: 20px;
    }
}

.modalFieldLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.clickable {
        cursor: pointer;
    }

    input {
        margin: 0 5px 0 0;
    }
}

.disabledIcon {
    position: absolute;
    top: 13px;
    right: 0;
    z-index: 1;
}

.noValidate :global(.error-message),
.noValidate :global(.validation-icon) {
    display: none;
}

.noValidate input {
    border-color: $clrBorderLight;
}

.modalHr {
    margin: 20px 0;
    border-color: #fff;
}

.modalFieldTextarea {
    height: 200px;

    &:focus {
        border-color: $clrGray2;

        & + div {
            border-color: $clrGray2 !important;
        }
    }
}

.field {
    padding: 11px 25px;
    border-bottom: 1px solid $clrBorderLight;
}

.modalContainer {
    align-self: flex-start;
    margin-top: 6%;
    width: 780px;
}

.modalTitle {
    font-size: 1.14rem;
    line-height: 2rem;

    & > div > span:first-child {
        background: #fff;
        width: 28px;
        height: 28px;
        text-align: center;
        color: #333;
        font-size: 1.43rem;
    }
}

.modalContent {
    padding: 20px 0;
    border-bottom: 1px solid #fff;

    p {
        font-style: normal;
    }

    .inputLabel {
        border-bottom: 1px solid #fff;
        margin: 10px 0 7px;
        padding-bottom: 7px;
    }
}

.socialIconsActions {
    margin-top: 20px;
    margin-bottom: 20px;
}

.socialIcon {
    padding: 15px;
    border-radius: 4px;
    background: $clrGray2;
    margin-bottom: 10px;
    vertical-align: middle;

    .colIcon,
    .colCheck {
        line-height: 2.714rem;
    }

    .colCheck {
        max-width: 16px;
    }

    .colPrefix {
        background: #F0F0F0;
        border: 1px solid $clrBorderLight;
        border-right: none;
        margin-left: 15px;
        margin-right: -15px;
        color: #777777;
        border-radius: 4px 0 0 4px;
        line-height: 2.57rem;
    }

    .withPrefix {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        color: $clrWhite;
        text-align: center;
        border-radius: 50%;
        vertical-align: middle;
        line-height: 2.14rem;
        font-size: 1.36rem;

        &.twitter {
            background: #000000;
        }

        &.linkedin {
            background: #007ab6;
        }

        &.facebook {
            background: #4866a8;
        }

        &.youtube {
            background: #ff3333;
        }

        &.googleplus {
            background: #c73d2d;
        }

        &.xing {
            background: #005a5f;
        }

        &.instagram {
            background: #517ea3;
            font-size: 1.14rem;
        }

        &.vimeo {
            background: #00aced;
        }

        &.pinterest {
            background: #c91f27;
            font-size: 1.64rem;
        }

        &.blog {
            background: #4f4f4f;
        }

        &.wechat {
            background: #51c332;
        }

        &.weibo {
            background: #e1162c;
        }

        &.youku {
            background: #00a8fe;
        }

        &.iconDisabled {
            background: #b1b4b3;
        }
    }
}

.emptyIcons {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    background: $clrWhite;
    color: #999999;
    border: 2px solid #999999;
    line-height: 1.714rem;
    font-size: 0.857rem;
    position: relative;
    z-index: 1;
    text-align: center;
}

.template .imageContainer {
    font-size: 0;
    line-height: 0;
    text-shadow: none;
    max-width: 100%;
    display: inline-block;
}

.socialTemplate {
    .formHint {
        background: $clrWhite;
    }

    li {
        list-style: disc;
        list-style-position: inside;
        margin: 5px 0;
    }

    .socialLabel,
    label {
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
    }

    .socialLabel {
        padding-top: 8px;
    }

    .fieldGroup {
        margin-bottom: 30px;
    }

    :global(.row) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        margin-bottom: 30px;
    }
}

.printTemplate {
    overflow-y: hidden;
}
