@import "src/styles/config";

.root {
    margin-bottom: 24px;
    overflow: hidden;
    box-sizing: border-box;
}

.kind-dark {
    .border {
        background-color: $clrGray;
        border-color: $clrGray;
    }

    .tab {
        border-color: $clrGray;
    }

    .active {
        background-color: $clrGray;
        color: $clrWhite;
    }
}

.border {
    padding-top: 10px;
    background-color: $clrGray1;
    border: 1px solid $clrBorder1;
}

.tabs {
    margin: 0 0 -1px 0;
    white-space: nowrap;
    display: flex;
}

.prefix,
.tab {
    display: inline-block;
    margin: 0 5px 0 0;
    border: 1px solid $clrBorder1;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    z-index: 4;
    user-select: none;
    min-width: 40px;

    &.disabled {
        border-color: rgba($clrBorder1, 0.5);

        & > .link {
            cursor: default;
            & > span {
                opacity: 0.4;
            }
        }
    }

    &.withAction .link {
        padding-right: 30px;
    }
}

.prefix {
    border-color: transparent;
    align-self: center;

    .link {
        padding-left: 0;
    }
}

.active {
    background-color: $clrGray1;
}

.link {
    display: block;
    padding: 10px;
    line-height: 1.43rem;
    color: $clrGray;
    text-decoration: none;
    float: left;
    box-sizing: border-box;
    min-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    cursor: pointer;
}

.count {
    font-size: 1.2em;
    display: block;
}

.right {
    float: right;
    margin-top: 5px;
}

.tabAction {
    border-radius: 50% !important;
    color: $clrWhite;
    text-decoration: none;
    position: absolute;
    right: 5px;


    &.add {
        background-color: $clrGreen;
    }

    &.remove {
        background-color: $clrError;
    }
}

.root.kind-wide {
    margin-bottom: 40px;
    overflow: visible;

    .border {
        display: none;
    }

    .tabs {
        display: flex;
        min-height: 3.929rem;
        margin: 0;
    }

    .tab {
        flex-basis: 50%;
        flex-grow: 1;
        margin: 0;
        border-radius: 0;
        border: none;
    }

    .link {
        text-align: center;
        font-weight: bold;
        float: none;
        padding: 0;
        border-radius: 0;
        border: 1px solid $clrBorderLight;
        white-space: normal;
        overflow: visible;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .link.active {
        width: auto;
        position: relative;
    }

    .link.active .arrow {
        display: block;
    }

    .arrow {
        display: none;
        position: absolute;
        top: auto;
        bottom: -20px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 20px;
        height: 20px;
        overflow: hidden;
    }

    .arrow span {
        height: 14px;
        width: 14px;
        border: 1px solid $clrBorder;
        border-radius: 2%;
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 12px;
    }
}
