@import "src/styles/config";

.wrapper {
    border: 2px dashed $clrTundora;
    z-index: 1;

    &.deleted {
        border-color: $clrError !important;

        .actions {
            flex-direction: column;
        }

        & > *:not(.actions, .overlay) {
            opacity: 0.8;
        }
    }

    .button {
        height: 20px;
        width: 20px;
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
        background: $clrTundora !important;
        color: $clrWhite !important;
        font-size: 0.857rem !important;
        line-height: 1.286rem !important;
        text-align: center !important;

        &:hover {
            background: darken($clrTundora, 20%) !important;
        }
    }

    .button.error {
        background: $clrError !important;
    }
}
.actions {
    display: flex;
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;

    &:hover + .overlay {
        opacity: 1;
        display: block;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }
}

.overlay {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .1);
    z-index: 2;
    transition: opacity 0.5s;

    &.loading {
        display: block;
        opacity: 1;
    }
}

.loader {
    height: 100%;
}
