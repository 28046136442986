.infoLink {
    padding: 10px 23px;
    display: inline-block;
    border-radius: 30px;
    border: 1px solid #d8d8d8;
    margin-top: 23px;
}

.infoLink img {
    width: 23px;
    height: 23px;
    float: left;
}

.size-small {
    padding: 5px 16px;
}
