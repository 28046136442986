@import "src/styles/config";

.title {
    margin: 30px -30px 23px;
    padding: 10px 30px;
    background-color: $clrGray1;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    border: 1px solid $clrBorder;
    background: $clrWhite;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: 25px;
    height: 25px;
}

.text {
    margin-bottom: 20px;
}
