@import "src/styles/config";

.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}

.item {
    height: 175px;
    padding: 5px;
    background: $clrWhite;
    border: 1px solid $clrBorder;
    align-items: center;
    justify-content: center;
    display: flex;
}

.item img {
    max-width: 100%;
    max-height: 100%;
}

.text {
    margin-top: 10px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.actions {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $clrNevada;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.5s;

    & > :global(.button) {
        margin: 7px 0;
    }
}

.wrapper {
    position: relative;
    &:not(.selected):hover .actions {
        transition: opacity 0.1s;
        opacity: 1;
    }
}

.selected {
    .item {
        border-width: 4px;
        border-color: $clrGreen;
        padding: 2px;
    }

    .actions {
        display: none;
    }
}
