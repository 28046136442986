// All the colors should be listed there
$clrSubText: #9d989d;
$clrBorder: #ccc;
$clrBorder1: #ddd;
$clrBorderLight: #e0e0e0;
$clrBlack: #000;
$clrDarkGray: #23323b;
$clrGray: #656565;
$clrGray1: #f4f5f7;
$clrGray2: #e2e2e3;
$clrGray3: #6b778c;
$clrLightGray: #f2f2f2;
$clrDarkGray2: #323A49;
$clrWhite: #fff;
$clrText: #656565;
$clrError: #d6514b;
$clrSuccess: #5cb85c;
$clrOrange: #f27f38;
$clrBackground2: #d8d8d8;
$clrTundora: #4D4D4D;
$clrNevada: #657076;
$clrYellow: #dd8524;
$clrYellowLight: #fce9d9;
$clrGreen: #5db85c;
$clrLightGreen: #dfedd8;
$clrRed: #db5350;
$clrLightRed: #f3dfde;
$clrOrangeCarrot: #ed9c28;
$clrOrangeDirty: #d9534f;
$clrDarkBlue: #172b4d;
$clrOrangePeel: #ffa101;
$clrNavyBlue: #05c;
$clrHeather: rgba(186, 190, 197, 0.5);
$clrDarkGreen: #023;
$colourGrayFunnel: #f0f0f0;
$colourSilverChalice: #adadad;
