$h: 30px;

.colour {
    display: inline-block;
    width: 15px;
    height: $h;

    & + .colour {
        margin-left: 4px;
    }
}

.more {
    display: inline-block;
    height: $h;
    line-height: $h;
    margin-left: 9px;
    vertical-align: top;
}
