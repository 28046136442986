@value clrGray, clrWhite from '../../vars.css';

.root {}

.icon {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 50%;
    background: clrGray;
    fill: clrWhite;
}

.info {
    user-select: none;
    cursor: pointer;
}

.arrow {
    margin-left: 5px;
}

.current {
    position: relative;
}

.current:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    left: 50%;
    top: calc(100% + 15px);
    z-index: 1001;

    display: inline-block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-bottom-color: inherit;
    border-color-top: transparent;
    border-color-left: transparent;
    border-color-right: transparent;
    transform: rotate(180deg);
}

.wideMenu {
    min-width: 210px;
}

.withShadow {
    padding-top: 10px;
    padding-bottom: 9px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 1px #c1c4d2;
}
