@value modalZIndex: 100;
@value smallWidth: 500px;
@value templateEditWidth: 720px;

.overlay {
    position: fixed;
    z-index: modalZIndex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(242, 242, 242, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    box-sizing: border-box;
    background-color: #23323b;
    border-radius: 8px;
    background-clip: padding-box;
    outline: 0;
    color: #fff;
    padding: 20px 30px;
}

.container-small {
    width: smallWidth;
}

.container-template-edit {
    width: templateEditWidth;
}


.container-light {
    background-color: transparent;
}
