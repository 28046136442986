@value clrBorder, fsTitle, clrText from '../../vars.css';

.wrapper > p {
    margin: 20px 0;
}

.contentHeader {
    overflow: hidden;
    border-bottom: 1px solid clrBorder;
    margin: 40px 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.titleWrapper {
    flex: 1;
}

.contentHeader.noHeader {
    border: none;
    margin: 30px 0;
}

.contentHeader .action {
    margin-top: 4px;
    margin-left: 15px;
}

.title {
    display: inline-block;
    margin: 0;
    height: 3.929rem;
    line-height: 3.929rem;
    font-size: fsTitle;
    color: #172b4d;
}

.info {
    float: right;
    margin: 12px 0 0 0;
    font-style: italic;
}

.action {
    float: right;
    margin-top: -15px;
    margin-left: 40px;
}

.basketWrapper {
    float: right;
    line-height: 3.929rem;
}

.text {
    font-size: 1rem;
    line-height: 2;
    margin-top: -15px;
    margin-bottom: 40px;
}

.oneTextAreaText1 {
    margin-bottom: auto;
}

.oneTextAreaText2 {
    margin-top: auto;
    margin-bottom: 25px;
}

.breadcrumbs {
    margin: 30px 0 -10px;
    overflow: hidden;
}

.breadcrumbs li {
    float: left;
    font-size: 0.86rem;
}

.breadcrumbs li:not(:first-child):before {
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent clrText;
    line-height: 0;
    float: left;
    display: block;
    margin: 7px 5px 5px;
}

.breadcrumbs li button,
.breadcrumbs li a {
    border: none;
    background: transparent;
    display: inline;
    text-decoration: underline;
    color: clrText;
    outline: none;
    cursor: pointer;
}
